<template>
<div style="display: flex;justify-content: center;align-items: center; height: 100%;">
    <el-card class="login-card">
        <div slot="header" class="clearfix">
            <span>登陆</span>
        </div>
        <div style="padding-right: 25px;" v-loading="loading">
            <el-form ref="form" :model="form" label-width="80px" @submit.native.prevent>
                <el-form-item label="账号" style="margin-bottom: 40px; margin-top: 30px;">
                    <el-input placeholder="请输入账号" v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" style="margin-bottom: 40px;">
                    <el-input type="password" placeholder="请输入密码" v-model="form.password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm" native-type="submit">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-card>
</div>
</template>
<script>
import api from "../api"
export default {
    data: () => ({
        loading: false,
        form: {
            username: "",
            password: ""
        }
    }),
    methods: {
        submitForm() {
            this.loading = true;
            api.login(this.form.username, this.form.password).then(res => {
                if (res.code == 0) {
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("uid", res.data.uid);
                    location.pathname = "/";
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch(err => {
                this.$message.error(err.toString());
                console.error(err);
                this.loading = false;
            });
        }
    }
}
</script>
<style scoped>
.login-card {
    width: 600px;
    height: 400px;
}
</style>